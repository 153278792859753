<template>
  <v-card
      class="mx-auto"
      elevation="0">
    <v-card-title class="card-header text-left fixed-card-title2">
      <v-row>
        <v-col cols="10">
          <v-toolbar-title >{{$t('edit-billing-link')}}</v-toolbar-title>
        </v-col>
        <v-col cols="2" class="text-right mt-n1">
          <v-icon color="white" @click="closeDrawer">mdi mdi-close</v-icon>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
<!--      <v-text-field-->
<!--          outlined-->
<!--          class="mt-3"-->
<!--          dense-->
<!--          disabled-->
<!--          v-model="loggedUser.organizationAssignedData.institutionName"-->
<!--          hide-details="auto"-->
<!--          :rules="[v => !!v || 'Item is required']"-->
<!--          :label="$t('Organization')"-->
<!--      >-->
<!--      </v-text-field>-->
      <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="-90"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="formattedBillingDate"
              :label="$t('billing-date')"
              readonly
              v-bind="attrs"
              hide-details
              prepend-inner-icon="mdi-calendar"
              dense
              v-on="on"
              class="my-2"
              outlined
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="formattedBillingDate"
            color="primary"
            type="month"
            no-title
            scrollable
            @change="menu1 = false"
        ></v-date-picker>
      </v-menu>

      <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="-90"
          offset-y
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="formattedDate"
              :label="$t('expires-at')"
              readonly
              v-bind="attrs"
              hide-details
              prepend-inner-icon="mdi-calendar"
              dense
              v-on="on"
              class="my-2"
              outlined
          ></v-text-field>
        </template>
        <v-date-picker v-model="item.expiresAt" color="primary" type="date" no-title scrollable @change="menu1 = false"></v-date-picker>
      </v-menu>
      <v-select
          outlined
          dense
          :items="formattedPartners"
          item-text="fullName"
          item-value="email"
          v-model="item.sendTo"
          :label="$t('select-partner')"
          single-line
          hide-details
      ></v-select>
      <v-text-field
          readonly
          outlined
          placeholder="johndoe@gmail.com"
          class="mt-3"
          dense
          v-model="item.sendTo"
          hide-details="auto"
          :rules="[v => !!v || 'Item is required']"
          :label="$t('send-to')"
      >
      </v-text-field>
    </v-card-text>
    <v-card-actions class="fixed-card-actions">
      <v-row class="mb-0">
        <v-col cols="6">
          <v-btn
              @click="closeDrawer"
              elevation="0"
              class="capitalize-text cancel-button-style">
            {{$t('cancel')}}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
              @click="editLink"
              class="save-button-style">
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import BillingsRepository from '@/data/repositories/v1/BillingsRepository';

export default {
  name: 'EditBillingLink.vue',
  data () {
    return {
      menu1: false,
      menu2: false,
      selectedDate: new Date().toISOString().substr(0, 7),
      sendTo: '',
      newSelectedPartner: null,
    };
  },
  computed: {
    ...mapState({
      partners: (state) => state.users.partners,
      item: (state) => state.item,
      loggedUser: (state) => state.authentication.userData,
    }),
    formattedDate () {
      if (this.item.expiresAt) {
        return this.item.expiresAt.split('T')[0];
      }
      return '';
    },
    formattedPartners () {
      return this.partners.map(partner => ({
        ...partner,
        fullName: `${partner.firstName} ${partner.lastName}`,
      }));
    },

      formattedBillingDate: {
        get () {
          const year = this.item.billingYear;
          const month = this.item.billingMonth;
          return `${year}-${month}`;
        },
        set (value) {
          const [year, month] = value.split('-');
          this.item.billingYear = parseInt(year, 10);
          this.item.billingMonth = parseInt(month, 10);
        },
      },
  },
  methods: {
    formatDate (dateTime) {
      if (dateTime) {
        return dateTime.split('T')[0];
      }
      return '';
    },
    async editLink () {
      const id = this.item.id;
      const request = {
        organizationId: this.loggedUser.organizationId,
        billingYear: this.formattedBillingDate.substring(0, 4),
        billingMonth: parseInt(this.formattedBillingDate.substring(5, 7), 10),
        expiresAt: new Date(`${this.formattedDate}T00:00:00Z`).toISOString(),
        sendTo: this.item.sendTo,
        status: this.item.status,
        partnerUserId: this.item.partnerUserId,
      };
      // console.log('REQUEST : ', request);
      // console.log('Partner : ', this.newSelectedPartner);
      try {
        await BillingsRepository.editBillingLink(request, id);
        await this.getBillingOrganizationLinks();
        this.closeDrawer();
        // eslint-disable-next-line no-empty
      } catch (err) {
      }

      this.$store.commit('SET_DETAILS_DRAWER', false);
    },
    async getBillingOrganizationLinks () {
      await this.$store.dispatch('billings/getBillingLinksForOrganization');
    },
    closeDrawer () {
      this.$store.commit('SET_UPDATE_DETAILS_DRAWER', false);
    },
  },

};
</script>
